@import '../themes/default';

.task-bar {
  z-index: $layer-content;
  box-sizing: border-box;
  border-radius: $border-radius;
  width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
  margin: 1em 0;

  &.task-bar-background {
    padding: 1em 2em;
    background-color: darken($bg, 2%);
  }

  > div {
    display: flex;
    justify-content: space-between;
    gap: 1em;
  }

  .task-bar-left {
    text-align: left;
  }

  .task-bar-center {
    text-align: center;
  }

  .task-bar-right {
    text-align: right;
  }
}

.modal-content .task-bar {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  padding-right: 1em;
}
