@import '../themes/default.scss';

.identicon {
  position: relative;
  top: 2px;

  canvas {
    border-radius: 50%;
  }
}
