@import '../themes/default';

$tag-min-size: 64px;
$tag-max-size: 200px;
$tag-fg: $brand-fg-3;

.tag {
  display: inline-block;
  margin: 0;
  padding: 0.2em 0.5em;
  min-width: $tag-min-size;
  max-width: $tag-max-size;
  border-radius: $border-radius;
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $tag-fg;
  background-color: transparent;
  transform: scale(1);
  opacity: 1;
  transform-origin: center;
  transition: background-color $transition-time ease-in-out,
    transform $transition-time ease-in-out,
    opacity $transition-time ease-in-out;

  svg {
    margin-right: 0.5em;
  }

  &.tag-clickable {
    cursor: pointer;
  }

  &.tag-closable {
    padding-right: 2.2em;
  }

  .tag-close-button {
    position: absolute;
    top: 8px;
    right: 4px;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    color: $tag-fg;
    opacity: 0.5;
    transition: opacity $transition-time ease-in-out;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  // Pulse animation
  &.tag-pulse {
    animation: tag-pulse 1.5s ease-in-out infinite both;
  }

  // Shake animation
  &.tag-shake {
    animation: tag-shake 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  }

  // Jump animation
  &.tag-jump {
    animation: tag-jump 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  }

  // Intents
  &.tag-none {
    background-color: $brand-fg-3;
    color: $brand-bg-1;
  }

  &.tag-info {
    background-color: $intent-info;
  }

  &.tag-success {
    background-color: $intent-success;
  }

  &.tag-warning {
    background-color: $intent-warning;
  }

  &.tag-danger {
    background-color: $intent-danger;
  }

  &.preEnter,
  &.exiting {
    opacity: 0;
    transform: scale(0);
  }

  &.exited {
    display: none;
  }
}

@keyframes tag-pulse {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(1.1);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(1.2);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

@keyframes tag-shake {
  0%,
  100% {
    transform: rotate(0deg);
  }
  10%,
  30%,
  50%,
  70% {
    transform: rotate(-3deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(3deg);
  }
  80% {
    transform: rotate(2deg);
  }
  90% {
    transform: rotate(-2deg);
  }
}

@keyframes tag-jump {
  0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateY(-3px);
  }
  20%,
  40%,
  60% {
    transform: translateY(3px);
  }
  80% {
    transform: translateY(2px);
  }
  90% {
    transform: translateY(-2px);
  }
}

.value,
.task-bar {
  .tag {
    height: 1.8em;
    line-height: 1.8em;
  }
}
