@import '../themes/default';

.animated-terminal {
  position: relative;

  .Terminal-window {
    background-color: $brand-bg-1;
    border-radius: $border-radius * 2;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    width: 100%;
    min-height: 400px;
    overflow: hidden;
    z-index: 1;
  }

  .Terminal-header .Terminal-btn {
    opacity: 0.5;

    &.Terminal-btn-close {
      background-color: $intent-danger;
    }

    &.Terminal-btn-minimize {
      background-color: $intent-warning;
    }

    &.Terminal-btn-maximize {
      background-color: $intent-success;
    }
  }

  .Terminal-control-btn {
    display: none;
  }

  .Terminal-code {
    font-family: $code-font-stack;
    font-size: 1.2em;
  }

  .Terminal-console {
    margin: 0;
    padding-left: 20px;
    width: calc(100% - 20px);
    height: calc(100% - 50px);
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background-color: $brand-bg-1;
    }
  }

  .Terminal-prompt {
    opacity: 0.5;
    user-select: none;
  }

  .Terminal-cursor {
    background-color: $brand-fg-1;
    position: relative;
    top: -1px;
    animation: cursor-fade-in-out 1s infinite;
  }

  @keyframes cursor-fade-in-out {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }

  .animations-container {
    position: absolute;
    top: 8px;
    right: 12px;
    z-index: 2;

    .button-animation {
      position: relative;
      float: none;
      width: 12px;
      height: 12px;
      padding: 0;
      margin: 0 0 0 0.45em;
      box-sizing: border-box;
      border: none;
      border-radius: 50%;
    }

    .button-animation-play {
      font-size: 0.9em;
      color: $brand-fg-2;
      background-color: transparent;
      opacity: 0.5;

      &:hover {
        color: $brand-fg-2;
        background-color: transparent;
        opacity: 1;
      }

      &:focus {
        color: $brand-fg-2;
        background-color: transparent;
        opacity: 0.5;
      }
    }

    .button-animation-select {
      background-color: $brand-fg-2;
      opacity: 0.5;
      transition-duration: 1s;

      &:hover {
        transition-duration: $transition-time;
      }

      &:hover,
      &.selected {
        background-color: $brand-fg-2;
        opacity: 1;
      }
    }
  }
}

.react-tooltip.animated-terminal-tooltip {
  z-index: $layer-modal;
  background-color: $tooltip-bg;
  color: $tooltip-fg;
  transition: opacity $transition-time ease-in-out;
  border-radius: $border-radius;

  &.react-tooltip__show {
    opacity: 1;
  }
}
