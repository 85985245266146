@import '../themes/default';

$cta-panel-border-radius: $border-radius * 2;

.cta-layout {
  width: 100%;
  height: 100%;
  background-color: darken($brand-bg-1, 4%);
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    width: 70%;
    height: 80%;
    padding: 0;
    display: flex;
    flex-direction: row;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  }

  .panel-left {
    position: relative;
    min-width: 240px;
    width: 50%;
    height: 100%;
    border-radius:
      $cta-panel-border-radius
      0
      0
      $cta-panel-border-radius;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    canvas#liquid-background {
      height: 100%;
      width: auto;
      aspect-ratio: 1 / 1;
      z-index: $layer-background;
    }

    .logo {
      position: absolute;
      top: 1em;
      left: 1em;
      width: 110px;
      z-index: $layer-content;
    }

    .panel-left-content {
      position: absolute;
      text-align: center;
      z-index: $layer-content;

      h1 {
        color: $brand-fg-2;
      }
    }
  }

  .panel-right {
    position: relative;
    min-width: 500px;
    width: 50%;
    height: 100%;
    background-color: $brand-fg-3;
    border-radius:
      0
      $cta-panel-border-radius
      $cta-panel-border-radius
      0;
    padding: 4em 2em;
    color: $brand-bg-2;
    box-sizing: border-box;
    text-align: center;

    .button-home {
      position: absolute;
      top: 1em;
      left: 1em;
    }

    h1 {
      color: $brand-bg-2;
    }

    a {
      color: $brand-bg-2;

      &:hover {
        color: fade-out($brand-bg-2, 0.5);
      }
    }

    form section {
      position: relative;
      width: 70%;
      text-align: left;
      margin: 1.5em auto;

      &:last-of-type {
        text-align: right;
      }
    }

    label {
      display: block;
      font-size: 0.8em;
      color: fade-out($brand-bg-2, 0.5);
    }

    input[type="text"],
    input[type="password"],
    input[type="email"] {
      width: 100%;
      margin: 0.5em 0;
      background-color: $brand-fg-3;
      border-color: fade-out($brand-bg-2, 0.5);
      color: $brand-bg-2;
      font-weight: bold;

      &:focus {
        border-color: $brand-bg-2;
      }
    }
  }
}

@media only screen and (max-width: ($page-small-width + 100px)) {
  .cta-layout {
    .inner {
      width: 70%;
    }

    .panel-left {
      display: none;
    }

    .panel-right {
      width: 100%;
      border-radius: $cta-panel-border-radius;
    }
  }
}

@media only screen and (max-width: $page-x-small-width) {
  .cta-layout {
    .inner {
      width: 100%;
      height: 100%;
    }

    .panel-right {
      padding: 2em 1em;
      border-radius: 0;
    }
  }
}
