@import '../themes/default';

canvas#circuit-background {
  z-index: $layer-background;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #31363F url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAF0lEQVQYV2NkQAKMUPZ/BgYGRhgHLAYAEnEBBJU1VYUAAAAASUVORK5CYII=) repeat;
}
