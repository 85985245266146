@import '../themes/default';

$rating-width: 50px;
$rating-height: 10px;
$rating-bg: fade-out($brand-fg-3, 0.9);
$rating-fg: $brand-fg-3;

.rating {
  position: relative;
  appearance: none;
  border: none;
  width: $rating-width;
  height: $rating-height;
  color: $rating-fg;
  transition: color $transition-time ease-in-out;

  &::-webkit-progress-bar {
    background-color: $rating-bg;
    border-radius: calc($border-radius / 2);
  }

  &::-webkit-progress-value {
    background-color: currentcolor;
    border-radius: calc($border-radius / 2);
    transition: width $transition-time ease-in-out;
  }
}
