@import '../themes/default';

.card {
  z-index: $layer-content;
  position: relative;
  padding: 1em 2em;
  box-sizing: border-box;
  border-radius: $border-radius;

  &.card-background {
    background-color: darken($bg, 2%);
  }
}
