@import '../themes/default';

.json-editor {
  position: relative;
  width: 100%;
  margin: 1em 0;
  border: $input-border-width solid $input-border;
  border-radius: $border-radius;
  transition: all $transition-time ease-in-out;

  code {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }

  textarea::placeholder {
    font-weight: normal;
  }

  &.json-editor-focussed {
    border-color: $input-focus-border;
  }

  code.language-json.code-highlight {
    .token.punctuation {
      color: $json-punctuation;
    }

    .token.property {
      color: $json-property;
      font-weight: bold;
    }

    .token.operator {
      color: $json-operator;
    }

    .token.string {
      color: $json-string;
    }

    .token.number {
      color: $json-number;
    }

    .token.boolean {
      color: $json-boolean;
    }

    .token.null.keyword {
      color: $json-null;
    }
  }

  &.json-editor-disabled {
    opacity: 0.4;
  }

  .json-editor-format-button {
    position: absolute;
    top: 0.75em;
    right: 0em;
  }
}
