@import './themes/default';

* {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  height: 16px;
  width: 16px;
}

*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: $scrollbar-bg;
}

*::-webkit-scrollbar-track:hover {
  background-color: $scrollbar-bg;
}

*::-webkit-scrollbar-track:active {
  background-color: $scrollbar-bg;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: $scrollbar-fg;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: $scrollbar-fg;
}

*::-webkit-scrollbar-thumb:active {
  background-color: $scrollbar-fg;
}

html,
body,
div#root {
  width: 100%;
  height: 100%;
}

body {
  font-family: $font-stack;
  font-size: $font-size;
  background-color: $bg;
  color: $fg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: $selection-bg;
  color: $selection-fg;
}

p {
  margin: 0 0 1em 0;
  line-height: 1.5em;
}

a {
  outline: 0;
  color: $link-fg;
  transition: color $transition-time ease-in-out;

  &:hover,
  &:focus {
    outline: 0;
    color: $link-hover-fg;
  }

  > svg {
    margin-right: 0.5em;
  }
}

code {
  font-family: $code-font-stack;
  background-color: darken($brand-bg-1, 4%);
  color: $brand-fg-2;
  padding: 0.1em 0.3em;
  border-radius: $border-radius;
}

.extra {
  padding: 0;
  margin: 0 0 0 0.25em;
  font-weight: normal;
  font-style: italic;
  font-size: 0.9em;
  color: fade-out($fg, 0.3);

  svg:first-child {
    margin-right: 0.5em;
  }
}

h1, h2, h3, h4 {
  margin: 0.5em 0 1.2em 0;
  font-family: $title-font-stack;

  a {
    text-decoration: none;
  }

  > svg:first-child {
    margin-right: 1em;
  }

  > span {
    position: relative;
  }

  .extra {
    padding: 0;
    margin: 0 0 0 0.5em;
    font-weight: normal;
    font-style: italic;
    font-size: 0.9em;
    color: fade-out($fg, 0.3);

    &:before {
      content: '(';
    }

    &:after {
      content: ')';
    }

    &:first-child {
      margin-left: 0;
      margin-right: 0.5em;
    }
  }

  .separator {
    margin: 0 0.5em;
    font-style: italic;
    font-size: 0.9em;
    color: fade-out($fg, 0.5);
  }
}

h1 {
  font-size: 1.4em;
  color: $fg;
  text-transform: uppercase;

  svg {
    position: relative;
    top: -1px;
    font-size: 1.2em;
  }
}

h2 {
  font-size: 1.2em;
  color: fade-out($fg, 0.3);
  text-transform: uppercase;
}

h3 {
  font-size: 1.2em;
  color: fade-out($fg, 0.3);
}

h4 {
  font-size: 1.2em;
  color: fade-out($fg, 0.6);
}

hr {
  border: 0;
  height: $input-border-width;
  background-color: $brand-bg-2;
  margin: 1em 0;
}

input {
  min-width: $input-min-width;
  box-sizing: border-box;
  outline: 0;
  border: $input-border-width solid $input-border;
  border-radius: $border-radius;
  background-color: $input-bg;
  color: $input-fg;
  font-family: $font-stack;
  font-size: $font-size;
  padding: 0.5em;
  margin: 1em 0;
  transition: all $transition-time ease-in-out;

  &::placeholder {
    color: $input-placeholder;
  }

  &:focus {
    outline: 0;
    border-color: $input-focus-border;
    background-color: $input-focus-bg;
    color: $input-focus-fg;
  }

  &:disabled {
    opacity: 0.4;
  }

  &[type="text"],
  &[type="password"],
  &[type="email"] {
    padding-right: 1.3em;
  }

  &.input-code {
    font-family: $code-font-stack;
  }
}

textarea {
  width: 100%;
  min-width: calc($input-min-width * 3);
  min-height: 8em;
  box-sizing: border-box;
  outline: 0;
  border: $input-border-width solid $input-border;
  border-radius: $border-radius;
  background-color: $input-bg;
  color: $input-fg;
  font-family: $font-stack;
  font-size: $font-size;
  padding: 0.5em;
  margin: 1em 0 0.5em 0;
  line-height: 1.5em !important;
  transition: all $transition-time ease-in-out;
  resize: vertical;

  &::placeholder {
    color: $input-placeholder;
  }

  &:focus {
    outline: 0;
    border-color: $input-focus-border;
    background-color: $input-focus-bg;
    color: $input-focus-fg;
  }

  &:disabled {
    opacity: 0.4;
  }
}

::-webkit-resizer {
  display: none;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
  list-style-image: none;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
  list-style-image: none;
}

ul[role="navigation"] {
  list-style: none;
  padding: 0;
  margin: 1em auto;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 1em;
    transition: opacity $transition-time ease-in-out;

    a {
      font-weight: bold;
      color: $link-fg;
      transition: color $transition-time ease-in-out;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $link-hover-fg;
        text-decoration: none;
      }

      svg {
        margin: 0;
      }
    }

    &.selected {
      a {
        color: $link-hover-fg;
      }
    }

    &.disabled {
      opacity: 0.2;

      a {
        cursor: default;
      }
    }
  }
}

blockquote {
  margin: 1em 0;
  padding: 0.5em 1em;
  border-left: 0.25em solid $brand-bg-2;
  background-color: $brand-bg-1;
  color: $brand-fg-2;
  font-style: italic;
}

.corner-tags-container {
  position: absolute;
  right: -0.5em;

  &.top {
    top: -0.5em;
  }

  &.bottom {
    bottom: -0.5em;
  }

  .tag {
    margin-left: 1em;
  }
}
