@import '../themes/default';

.linkable-header {
  .linkable-header-copy-to-clipboard-button {
    opacity: 0;
  }

  &:hover {
    .linkable-header-copy-to-clipboard-button {
      opacity: 0.5;
    }
  }
}
