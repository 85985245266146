@import '../themes/default';

$menu-bg: darken($brand-bg-1, 4%);
$menu-min-width: 200px;

.documentation-contents-menu {
  grid-area: "documentation-menu";
  background-color: $menu-bg;
  min-width: $menu-min-width;
  line-height: 3em;
  font-size: 0.9em;

  .documentation-contents-menu-inner {
    position: sticky;
    top: 0;
    align-self: start;
    padding: 0.7em 2.5em 9em 2em;
    height: calc(100vh - $bar-size - 2em);
    overflow-y: auto;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;

    &.documentation-contents-item-active {
      text-decoration: underline;
    }
  }

  .documentation-contents-item-section {
    color: $brand-fg-2;
    outline: 0;
    cursor: pointer;
    transition: color $transition-time ease-in-out;

    &:hover {
      color: $brand-fg-3;
    }
  }

  .documentation-contents-icon {
    width: 1.5em;
    margin-right: 1em;
  }

  .documentation-contents-item-page {
    color: $brand-fg-2;

    &:hover {
      color: $brand-fg-3;
    }
  }

  .documentation-contents-section-collapse {
    display: inline-block;
    width: 100%;

    .collapse-inner {
      padding: 0 0 0 1em;
      margin: 0;
    }
  }

  .documentation-contents-heading {
    font-size: 1em;
  }
}
