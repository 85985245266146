@import '../themes/default';
@import './Menu.scss';
@import './UserMenu';

$header-height: $bar-size;
$header-bg: darken($brand-bg-1, 2%);
$header-fg: $brand-fg-3;

.header {
  z-index: $layer-header;
  grid-area: header;
  display: grid;
  grid-template-areas:
    "title search-bar user-menu";
  grid-template-rows: 1fr;
  grid-template-columns: $menu-width 1fr $user-menu-size;
  background-color: $header-bg;
  color: $header-fg;
  height: $header-height;

  &.header-hide-user-menu {
    grid-template-areas: "title search-bar";
    grid-template-columns: $menu-width 1fr;
  }
}
