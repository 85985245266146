@import '../themes/default';

$resource-identifier-fg: $brand-fg-3;

.resource-identifier {
  position: relative;
  display: inline-block;
  margin: 0.5em;
  padding: 0.55em 1em !important;
  line-height: 1.5em !important;
  border-radius: $border-radius;
  font-size: 0.9em;
  font-weight: bold;
  white-space: nowrap;
  color: $resource-identifier-fg;
  background-color: darken($brand-bg-1, 6%);

  a {
    text-decoration: none;
  }

  svg {
    margin: 0 1em 0 0;
  }

  .extra {
    margin-left: 1em;
  }

  &.resource-identifier-wrap {
    display: block;
  }

  // &.resource-identifier-closable,
  // &.resource-identifier-copyable {
  //   padding-right: 2.6em !important;
  // }

  // &.resource-identifier-closable.resource-identifier-copyable {
  //   padding-right: 4.6em !important;
  // }

  .resource-identifier-copy-to-clipboard-button {
    position: relative;
    top: 0.35em;
    left: 0.7em;
    float: right;
    width: 1em;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0 0.4em;
    color: $resource-identifier-fg;
    opacity: 0.5;
    transition: opacity $transition-time ease-in-out;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  .resource-identifier-close-button {
    position: relative;
    top: 0.35em;
    left: 0.7em;
    float: right;
    width: 1em;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0 0.4em;
    color: $resource-identifier-fg;
    opacity: 0.5;
    transition: opacity $transition-time ease-in-out;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }
}
