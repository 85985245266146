@import '../themes/default';

.tabs {
  ul {
    border-bottom-width: $input-border-width;
    border-bottom-color: $input-border;
  }

  .react-tabs__tab {
    background-color: darken($brand-bg-1, 6%);
    border-radius: $border-radius $border-radius 0 0;
    margin: 0 0 0 0.5em !important;
    color: fade-out($input-fg, 0.5);
    font-size: 0.8em;
    border: none;
    bottom: 0;
    transition: all $transition-time ease-in-out;

    &:hover {
      color: $input-fg;
    }

    &--selected {
      background-color: $input-border;
      color: $input-fg;
    }

    &:focus:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: $input-border-width;
      background-color: $input-focus-border;
    }
  }
}
