@import '../themes/default';

.icon-sprite {
  display: inline-block;
  position: relative;
  width: 2em;
  height: 2em;

  .icon-sprite-layer {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
