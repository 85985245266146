@import '../themes/default';

canvas#liquid-background {
  z-index: $layer-background;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
