@import '../themes/default';

.footer {
  z-index: $layer-footer;
  position: absolute;
  grid-area: footer;
  bottom: 0px;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
  text-align: center;

  div {
    a img {
      opacity: 0.3;
      transition: opacity $transition-time ease-in-out;
    }

    a:hover img {
      opacity: 1;
    }
  }

  .made-in-japan {
    float: left;
    // display: inline-block;
    margin: 0 auto;
    font-size: 0.8em;
    opacity: 0.5;
  }

  .fl3x-button {
    float: right;
    position: relative;
    top: -10px;

    a img {
      width: 2em;
    }
  }

  .gl-button {
    display: none;
    float: left;

    a img {
      width: 5em;
    }
  }
}
