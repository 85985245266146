@import '../themes/default';

.resource-picker {
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: $input-bg;
  border-radius: $border-radius !important;
  margin: 1em 0;

  .resource-picker-select {
    margin: 0 !important;
    width: 100%;

    .select__indicators {
      margin-left: 1.5em;
    }
  }

  .resource-picker-refresh-button {
    position: absolute;
    right: 1.5em;
    bottom: 0.7em;
    color: $input-placeholder !important;
    cursor: default;
  }

  &.resource-picker-disabled {
    opacity: 0.4;
  }

  .resource-identifier {
    margin: 0.5em !important;
  }
}
