@import '../themes/default';

$user-menu-size: $bar-size;
$user-menu-bg: darken($brand-bg-1, 2%);
$user-menu-fg: $brand-fg-2;
$user-menu-hover-fg: $brand-fg-3;

.user-menu {
  user-select: none;
  grid-area: "user-menu";
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $user-menu-size;
  height: $user-menu-size;
  background-color: $user-menu-bg;
  color: $user-menu-fg;
  cursor: pointer;
  transition: all $transition-time ease-in-out;

  > svg {
    height: 30%;
  }

  &:hover {
    color: $user-menu-hover-fg;
  }
}

.react-tooltip.user-menu-tooltip {
  z-index: $layer-modal;
  background-color: $tooltip-bg;
  color: $tooltip-fg;
  transition: opacity $transition-time ease-in-out;
  border-radius: $border-radius;
  padding: 0.2em 0;

  &.react-tooltip__show {
    opacity: 1;
  }

  .user-display-name {
    display: block;
    margin: 0.5em 0;
    padding: 0.5em 1em 1em 1em;
    border-bottom: 1px fade-out($brand-fg-3, 0.8) dotted;
  }

  .button {
    display: block;
    margin: 0;
    width: 160px;
    text-align: left;
    font-size: 1.1em;
    background-color: $tooltip-bg;
    cursor: pointer;

    &.button-secondary {
      &:hover,
      &:focus {
        border-color: transparent;
        color: $user-menu-hover-fg;
      }
    }

    .button-icon {
      margin-right: 1em;
    }
  }
}
