@import '../themes/default';

$menu-width: $bar-size;
$menu-bg: darken($brand-bg-1, 2%);
$menu-fg: $brand-fg-2;
$menu-hover-bg: $brand-bg-2;
$menu-active-bg: darken($brand-bg-1, 6%);
$menu-hover-fg: $brand-fg-3;

.menu {
  z-index: $layer-menu;
  user-select: none;
  grid-area: menu;
  background-color: $menu-bg;
  font-size: small;
  font-weight: bold;
  width: $menu-width;
  transition: width $transition-time ease-in-out,
    min-width $transition-time ease-in-out;

  .menu-inner {
    position: sticky;
    top: 0;
    align-self: start;

    .collapse .collapse-inner {
      margin: 0;
      padding: 0;
    }
  }
}

.react-tooltip.menu-tooltip {
  z-index: $layer-modal;
  background-color: $tooltip-bg;
  color: $tooltip-fg;
  transition:
    opacity $transition-time ease-in-out,
    top $transition-time ease-in-out;
  border-radius: $border-radius;

  &.react-tooltip__show {
    opacity: 1;
  }
}
