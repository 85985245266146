@import '../themes/default';

$alert-fg: $brand-fg-3;

.alert {
  position: relative;
  margin: 1em 0 2em 0;
  padding: 10px 40px;
  border-radius: $border-radius;
  color: $alert-fg;
  font-weight: normal;
  line-height: 1.4em;
  text-align: left;
  min-height: 1.3em;
  transform: scale(1, 1);
  height: auto;
  overflow: visible;
  opacity: 1;
  transform-origin: center top;
  transition: transform $transition-time ease-in-out,
    opacity $transition-time ease-in-out,
    height $transition-time ease-in-out,
    margin $transition-time ease-in-out,
    background-color $transition-time ease-in-out;

  .alert-icon {
    position: absolute;
    top: 10px;
    left: 14px;
    color: $alert-fg;
  }

  .alert-close-button {
    position: absolute;
    top: 13px;
    right: 14px;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    color: $alert-fg;
    opacity: 0.5;
    transition: opacity $transition-time ease-in-out;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  .alert-content {
    font-size: 0.9em;

    a {
      color: $alert-fg;
      text-decoration: underline;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &.alert-info {
    background-color: $intent-info;
  }

  &.alert-success {
    background-color: $intent-success;
  }

  &.alert-warning {
    background-color: $intent-warning;
  }

  &.alert-danger {
    background-color: $intent-danger;
  }

  &.preEnter,
  &.exiting {
    opacity: 0;
    transform: scale(1, 0);
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  &.exited {
    display: none;
  }
}
