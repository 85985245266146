@import '../themes/default';
@import './Menu';

.title {
  user-select: none;
  grid-area: title;
  width: $menu-width;
  transition: width $transition-time ease-in-out,
    min-width $transition-time ease-in-out;

  > a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-logo {
    position: relative;
    width: 60%;
  }
}
