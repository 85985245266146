@import '../themes/default';

.chart-tooltip {
  z-index: $layer-modal;
  background-color: $tooltip-bg;
  color: $tooltip-fg;
  transition: opacity $transition-time ease-in-out;
  border-radius: $border-radius;
  padding: 0.6em 1em;
  font-size: 0.9em;

  &.show {
    opacity: 1;
  }

  table td:first-of-type {
    font-weight: bold;
    padding-right: 1em;
  }

  table td:last-of-type {
    text-align: right;
  }
}
