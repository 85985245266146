@import '../themes/default';

.login-page {
  .auth-button {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 5px;
    margin: 0 1em 2em 1em;
    border-color: fade-out($brand-fg-2, 0.5);

    .button-icon {
      font-size: 1.5em;
    }
  }
}
