@import '../themes/default';

.value-list {
  z-index: $layer-content;
  border-spacing: 0;

  &.value-list-inline {
    display: inline-table;
    margin-right: 2em;
  }

  &.value-list-highlight-alt-rows tbody tr:nth-child(even) {
    background-color: lighten($bg, 2%);
  }

  &.value-list-full-width {
    width: 100%;
  }
}
