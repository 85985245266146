@import '../themes/default';

$datepicker-icon: fade-out($fg, 0.7);
$datepicker-dropdown-bg: darken($brand-bg-1, 6%);
$datepicker-dropdown-fg: $brand-fg-2;
$datepicker-dropdown-hover-fg: $brand-fg-3;

.datepicker {
  display: inline-block;
  position: relative;
  margin: 1em 0;

  .datepicker-input {
    padding-right: 2.5em;
    margin: 0;
  }

  .datepicker-icon {
    display: inline-block;
    position: absolute;
    top: calc(50% - 0.55em) !important;
    right: 1em;
    height: 1.5em;
    color: $datepicker-icon;
    transition: color $transition-time ease-in-out;

    &:hover {
      color: $fg;
    }
  }

  .datepicker-clear-button {
    position: absolute;
    right: 1.6em;
    bottom: 0.7em;
    color: $datepicker-icon !important;
    cursor: default;
  }
}

.value-data .datepicker .datepicker-icon {
  top: calc(50% - 0.5em);
}

.react-tooltip.datepicker-tooltip {
  z-index: $layer-modal;
  background-color: $datepicker-dropdown-bg;
  color: $datepicker-dropdown-fg;
  transition: opacity $transition-time ease-in-out;
  border-radius: $border-radius;
  padding: 0.5em 0;

  &.react-tooltip__show {
    opacity: 1;
  }

  .rdp {
    .rdp-button,
    .rdp-day {
      transition: all $transition-time ease-in-out;

      &:hover {
        background-color: $datepicker-dropdown-fg;
        color: $datepicker-dropdown-bg;
      }

      &_selected {
        background-color: $datepicker-dropdown-bg;
      }
    }
  }
}
