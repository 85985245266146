@import '../themes/default';

$loading-size: 32px;

.loading {
  z-index: $layer-modal;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $bg;
  opacity: 0.8;
  transition: opacity $transition-time ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: $loading-size;
    height: $loading-size;
  }

  &.preEnter,
  &.exiting {
    opacity: 0;
  }

  &.exited {
    display: none;
  }
}
