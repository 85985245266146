@import '../themes/default';
@import '../components/Header';

.dashboard-layout {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: $page-min-width;
  display: grid;
  grid-template-areas:
    "header header"
    "menu content"
    "menu footer";
  grid-template-rows: $header-height 1fr;
  grid-template-columns: $menu-width 1fr;
  text-align: left;

  .dashboard-content {
    z-index: $layer-content;
    grid-area: content;
    background-color: $bg;
    padding: 1em 2em 4em 2em;
    line-height: 1.5em;
  }
}
