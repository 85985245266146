@import '../themes/default';

$checkbox-bg: $input-bg;
$checkbox-fg: $input-fg;
$checkbox-border: $input-border;
$checkbox-focus-border: $input-focus-border;
$checkbox-size: 1.4em;
$checkbox-inner-size: calc($checkbox-size / 2);

.checkbox {
  display: inline-block;
  position: relative;
  top: 2px;
  font-size: 1em;
  display: grid;
  grid-template-columns: $checkbox-size auto;
  gap: 0.6em;
  padding: 0.5em 0;
  margin: 1em 0.4em 1em 0;
  color: $input-fg;

  &.checkbox-no-label {
    width: calc($checkbox-size + 1em);
  }

  &.checkbox-disabled {
    opacity: 0.4;
  }

  input[type="checkbox"] {
    display: inline-block;
    appearance: none;
    margin: 0;
    background-color: $checkbox-bg;
    width: $checkbox-size;
    height: $checkbox-size;
    min-width: auto;
    border-radius: $border-radius;
    border: $input-border-width solid $checkbox-border;
    display: grid;
    place-content: center;
    transition: all $transition-time ease-in-out;

    &::before {
      content: "";
      width: $checkbox-inner-size;
      height: $checkbox-inner-size;
      transform: scale(0);
      opacity: 0;
      transition:
        transform $transition-time ease-in-out,
        opacity $transition-time ease-in-out;
      box-shadow: inset 1em 1em $checkbox-fg;
      border-radius: calc($border-radius / 2);
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    &:checked::before {
      transform: scale(1);
      opacity: 1;
    }

    &:focus {
      border-color: $checkbox-focus-border;
    }

    &:disabled {
      opacity: 1;
    }
  }
}
