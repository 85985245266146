@import '../themes/default';

$search-bar-height: $bar-size;
$search-button-size: $bar-size;
$search-bar-bg: darken($brand-bg-1, 2%);
$search-bar-fg: $input-fg;
$search-bar-focus-bg: $search-bar-bg;
$search-bar-focus-fg: $input-fg;
$search-bar-placeholder: $input-placeholder;

.search-bar {
  position: relative;
  grid-area: "search-bar";
  display: flex;
  flex-grow: 100;

  form {
    display: flex;
    flex-grow: 100;
  }

  input {
    flex-grow: 100;
    height: $search-bar-height;
    margin: 0;
    padding: 1em;
    border-radius: 0;
    border: 0;
    background-color: $search-bar-bg;

    &::placeholder {
      color: transparent;
      transition: all $transition-time ease-in-out;
    }

    &:hover,
    &:focus,
    &.has-value {
      outline: 0;
      background-color: $search-bar-focus-bg;
      color: $search-bar-focus-fg;

      &::placeholder {
        color: $search-bar-placeholder;
      }
    }
  }

  button {
    height: $search-button-size;
    width: $search-button-size;
    margin: 0;
    border: 0;
    border-radius: 0;

    svg {
      margin-left: 0.5em;
    }

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      outline: 0;
      color: $search-bar-focus-fg;
    }

    &.submit-search-button {
      background-color: $search-bar-bg;
    }

    &.reset-search-button {
      position: absolute;
      right: $search-button-size;
      background-color: transparent;
      opacity: 0;
      transition: opacity $transition-time ease-in-out;

      &.has-value {
        opacity: 1;
      }
    }
  }

  .tag {
    position: absolute;
    top: 1.2em;
    right: $search-button-size * 2;
    transition-delay: 0.1s;
  }
}
