@import '../themes/default';

.token-card {
  padding: 0 2em 1em 2em;

  .task-bar {
    padding: 0 !important;
    margin-bottom: 0;

    .switch {
      margin-top: 0;
    }
  }

  &.token-locked,
  &.token-expired {
    > * {
      opacity: 0.5;
    }

    .corner-tags-container {
      opacity: 1;
    }
  }
}
