@import '../themes/default';

.card-list {
  width: 100%;
  height: auto;
  display: grid;
  grid-auto-flow: row;
  gap: 1em;

  &.card-list-horizontal {
    grid-auto-flow: column;
  }
}
