@import '../themes/default';

.code-viewer {
  position: relative;
  width: 100%;
  margin: 1em 0.5em 1em 0;
  box-sizing: border-box;
  border: $input-border-width solid $input-border;
  border-radius: $border-radius;

  display: grid;
  grid-template-areas:
    "header header"
    "line-numbers code";
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;

  .code-viewer-header {
    position: relative;
    grid-area: header;
    padding: 0 0.5em $input-border-width 0.5em;
    background-color: $input-bg;

    .code-viewer-language-select {
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0;
      margin: 0 0.4em;
      color: $brand-fg-3;
      opacity: 0.5;
      transition: opacity $transition-time ease-in-out;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    }

    .code-viewer-copy-to-clipboard-button {
      font-size: 1.1em;
      position: absolute;
      top: calc(0.5em + $input-border-width);
      right: 0.5em;
      width: 1em;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0;
      margin: 0 0.4em;
      color: $brand-fg-3;
      opacity: 0.5;
      transition: opacity $transition-time ease-in-out;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    }
  }

  .code-viewer-line-numbers {
    grid-area: "line-numbers";
    width: 2em;
    padding: 1em;
    font-family: $code-font-stack;
    font-size: 1em;
    line-height: 1.5em;
    text-align: right;
    color: fade-out($brand-fg-3, 0.5);
    background-color: darken($brand-bg-1, 4%);
    border-right: $input-border-width solid $input-border;
    border-radius: $border-radius 0 0 $border-radius;

    span {
      display: block;
    }
  }

  .code-viewer-code {
    grid-area: code;
    font-family: $code-font-stack;
    font-size: 1em;
    line-height: 1.5em;
    margin: 1em 0 0 0;
    padding: 0 1em 1em 1em;
    white-space: nowrap;
    overflow: auto;
    background-color: transparent;

    &::-webkit-scrollbar-track {
      border-radius: 0px;
      background-color: transparent;
    }

    .code-identifier {
      color: $code-identifier;
      font-weight: bold;
    }

    .code-keyword {
      color: $code-keyword;
    }

    .code-string {
      color: $code-string;
    }

    .code-number {
      color: $code-number;
    }

    .code-comment {
      color: $code-comment;
    }

    .code-punctuation {
      color: $code-punctuation;
    }

    .code-operator {
      color: $code-operator;
    }
  }
}
