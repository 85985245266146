@import '../themes/default';

$documentation-page-small-width: 1200px;

.documentation-layout {
  .dashboard-content {
    display: grid;
    grid-template-areas: "documentation-menu documentation-content";
    grid-template-columns: auto 1fr;
    padding: 0;
  }

  .documentation-content {
    position: relative;
    grid-area: "documentation-content";
    padding: 1em 2em 4em 2em;

    p {
      line-height: 1.8em;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 2em;
    }

    h2, h3 {
      padding-bottom: 1em;
      border-bottom: $input-border-width solid $brand-bg-2;
    }

    hr {
      margin: 2em 0;
    }

    ul, ol {
      li {
        margin: 0.5em 0;
        line-height: 1.8em;
      }
    }

    .code-viewer {
      background-color: darken($bg, 2%);
    }

    .json-viewer {
      background-color: darken($bg, 2%);
    }

    .sections-container {
      a {
        text-decoration: none;
      }

      .section {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-areas: "icon content";
        border: $input-border-width solid $input-border;
        border-radius: $border-radius;
        margin: 2em 0;
        transition: border-color $transition-time ease-in-out;
        background-color: fade-out($brand-bg-2, 0.95);

        &:hover {
          border-color: lighten($input-border, 5%);
        }

        .section-icon {
          align-self: center;
          grid-area: icon;
          font-size: 2.2em;
          margin: 0 1em;
        }

        .section-description {
          grid-area: content;

          h1 {
            margin-top: 1em;
          }
        }

        &.section-lists,
        &.section-how-it-works,
        &.section-sample-apps {
          border-color: fade-out($intent-info, 0.5);
          background-color: fade-out($intent-info, 0.95);

          &:hover {
            border-color: $intent-info;
          }
        }

        &.section-items,
        &.section-getting-started {
          border-color: fade-out($intent-success, 0.5);
          background-color: fade-out($intent-success, 0.95);

          &:hover {
            border-color: $intent-success;
          }
        }

        &.section-indexes,
        &.section-api-reference {
          border-color: fade-out($intent-warning, 0.5);
          background-color: fade-out($intent-warning, 0.95);

          &:hover {
            border-color: $intent-warning;
          }
        }

        &.section-identities {
          border-color: fade-out($intent-danger, 0.5);
          background-color: fade-out($intent-danger, 0.95);

          &:hover {
            border-color: $intent-danger;
          }
        }
      }
    }

    .api-route {
      display: block;
      box-sizing: border-box;
      width: 100%;
      padding: 1em;
      margin: 1em 2em 1em 0;
      font-size: large;
      background-color: darken($bg, 2%);
      border: $input-border-width solid $input-border;
      border-radius: $border-radius;

      .http-method {
        color: $http-method;
        font-weight: bold;
      }

      .http-url {
        color: $http-url;
      }
    }

    .object-overview {
      display: grid;
      grid-template-areas: "attributes viewer";
      grid-template-columns: 1fr 1fr;

      .object-viewer {
        grid-area: viewer;
        position: sticky;
        top: 2em;
        align-self: start;
        background-color: darken($bg, 2%);

        &.tabs {
          background-color: transparent;

          ul {
            border-bottom: none;
          }

          .react-tabs__tab-list {
            margin-bottom: 0;
          }
        }
      }
    }

    .attributes {
      grid-area: attributes;

      > ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          position: relative;
          padding: 0.25em 0;

          .tag {
            position: absolute;
            top: 2.25em;
            right: 0;
          }
        }

        > li {
          padding: 1em 0;
          margin: 0 3em 1em 0;
          border-bottom: $input-border-width solid $brand-bg-2;
        }

        li:last-of-type {
          border-bottom: none;
        }
      }

      .attribute {
        display: block;
        font-size: large;
        font-weight: bold;
        margin: 1em 0;
        padding: 0;
        background-color: transparent;

        .attribute-type {
          font-size: medium;
          padding: 0 1em;
          color: fade-out($fg, 0.5);
        }

        .attribute-multi-line {
          padding-left: 0;
        }
      }

      .attribute-description {
        display: inline-block;
        padding: 1em 0;

        .tag {
          float: left;
          margin-right: 1em;
          position: relative;
          top: -0.25em;
        }
      }

      .attribute-example {
        display: block;
        padding: 1em 0;
        font-size: medium;
        color: fade-out($fg, 0.5);
        background-color: transparent;

        &:before {
          content: "Example: ";
          font-weight: bold;
        }
      }
    }

    .documentation-collapse-header {
      padding: 0.5em;
      font-size: small;
      cursor: pointer;

      svg {
        margin: 0 0.5em;
      }
    }

    .response-status-tag {
      margin-bottom: 1em;
    }

    .documentation-last-updated {
      font-size: small;
      color: fade-out($fg, 0.5);
      font-style: italic;
      margin-top: 4em;
      float: left;

      &::before {
        content: "Last updated ";
      }
    }
  }
}

@media only screen and (max-width: $documentation-page-small-width) {
  .documentation-layout {
    .documentation-content {
      .object-overview {
        grid-template-areas:
          "viewer"
          "attributes";
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;

        .object-viewer {
          position: relative;
          top: 0;
          align-self: start;
        }
      }
    }
  }
}

@media only screen and (max-width: $page-small-width) {
  .documentation-layout {
    .dashboard-content {
      grid-template-areas:
        "documentation-menu"
        "documentation-content";
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    }
  }
}
