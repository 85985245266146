@import '../themes/default';

.social-links {
  .social-link-icon {
    position: relative;
    top: 5px;
    font-size: 24px;
  }

  .social-link-label {
    font-size: normal;
  }

  &.horizontal {
    .social-link {
      display: inline-block;
      margin-right: 0.4em;
    }
  }

  &.vertical {
    text-align: left;

    .social-link {
      display: block;
      margin-bottom: 1em;
    }
  }

  &.hide-labels {
    .social-link-label {
      display: none;
    }
  }
}
