@import '../themes/default';

.json-compare {
  .task-bar .task-bar-right .value {
    text-align: left;
  }

  .json-compare-inner {
    background-color: $brand-bg-1;
    border: $input-border-width solid $input-border;
    border-radius: $border-radius;
  }

  table tbody tr td a {
    font-weight: bold;
    text-decoration: none !important;
  }

  td pre {
    font-family: $code-font-stack;
    font-size: 1em;
    line-height: 1.5em;
  }
}
