@import '../themes/default';
@import './SearchBar';

$search-results-menu-bg: darken($brand-bg-1, 6%);

.search-results-menu {
  position: absolute;
  top: calc($search-bar-height + 10px);
  left: 10px;
  width: calc(100% - $bar-size - 20px);
  min-height: 1.3em;
  transform: scale(1, 1);
  overflow: visible;
  opacity: 1;
  transform-origin: center top;
  transition: transform $transition-time ease-in-out,
    opacity $transition-time ease-in-out;
  background-color: $search-results-menu-bg;
  border-radius: $border-radius;
  padding: 1em;
  box-sizing: border-box;

  .search-results-menu-arrow {
    position: absolute;
    top: -6px;
    right: 110px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $search-results-menu-bg;
  }

  &.preEnter,
  &.exiting {
    opacity: 0;
    transform: scale(1, 0);
    overflow: hidden;
  }

  &.exited {
    display: none;
  }
}
