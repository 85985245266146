@import '../themes/default';

$select-dropdown-bg: darken($brand-bg-1, 6%);
$select-dropdown-fg: $brand-fg-2;
$select-dropdown-hover-fg: $brand-fg-3;

.select-container {
  display: inline-block;
  width: auto;
  min-width: $input-min-width;
  position: relative;
  text-align: left;
  margin: 1em 0;

  .css-mohuvp-dummyInput-DummyInput {
    display: none;
  }

  .select {
    &__control {
      background-color: $input-bg !important;
      outline: 0 !important;
      box-shadow: none !important;
      border: $input-border-width solid $input-border !important;
      border-radius: $border-radius !important;
      padding: 0.06em !important;

      .select__value-container input {
        margin: 0;
        min-width: 0;
      }

      &--is-focused {
        outline: 0 !important;
        box-shadow: none !important;
        border-color: $input-focus-border !important;
        background-color: $input-focus-bg !important;
        color: $input-focus-fg !important;
      }

      &--is-disabled {
        opacity: 0.4 !important;
      }
    }

    &__input-container,
    &__single-value {
      color: $input-fg !important;
      font-family: $font-stack !important;
      font-size: $font-size !important;
      font-weight: normal !important;

      .select-option-icon {
        display: inline-block;
        width: 1em;
        margin-right: 1em;
      }

      .select-extra {
        margin-left: 1em;
        font-size: 0.9em;
        font-style: italic;
        color: fade-out($fg, 0.3);

        &:before {
          content: '(';
        }

        &:after {
          content: ')';
        }
      }
    }

    &__input {
      padding: 0 !important;
    }

    &__placeholder {
      color: $input-placeholder !important;
      transition: color $transition-time ease-in-out !important;
      font-weight: normal !important;
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      color: $input-placeholder !important;
      transition: color $transition-time ease-in-out !important;

      &:hover {
        color: $input-fg !important;
      }
    }

    &__menu {
      z-index: $layer-modal !important;
      background-color: $select-dropdown-bg !important;
      margin-top: 10px !important;

      > *::-webkit-scrollbar {
        width: 8px;
      }

      > *::-webkit-scrollbar-track {
        background: $select-dropdown-bg;
        border-radius: $border-radius;
      }

      > *::-webkit-scrollbar-thumb {
        background-color: $select-dropdown-fg;
        border-radius: calc($border-radius / 2);
        border: 0px solid $select-dropdown-bg;
      }
    }

    &__option {
      background-color: transparent !important;
      color: $select-dropdown-fg !important;
      transition: color $transition-time ease-in-out !important;
      padding-left: 1em !important;
      font-size: 1em !important;
      font-weight: normal !important;
      white-space: nowrap !important;
      cursor: pointer;

      &--is-focused {
        color: $select-dropdown-hover-fg !important;
      }

      .select-option-icon {
        display: inline-block;
        width: 1em;
        margin-right: 1em;
      }

      .select-extra {
        margin-left: 1em;
        font-size: 0.9em;
        font-style: italic;
        color: fade-out($fg, 0.3);

        &:before {
          content: '(';
        }

        &:after {
          content: ')';
        }
      }
    }
  }

  &.select-container-light {
    .select {
      &__control {
        background-color: $brand-fg-3 !important;
      }

      &__input-container,
      &__single-value {
        color: $brand-bg-2 !important;

        .select-extra {
          color: fade-out($brand-bg-2, 0.3) !important;
        }
      }

      &__placeholder {
        color: fade-out($brand-bg-2, 0.3) !important;
      }

      &__dropdown-indicator {
        color: fade-out($brand-bg-2, 0.3) !important;

        &:hover {
          color: $brand-bg-2 !important;
        }
      }
    }
  }
}

.react-tooltip.select-menu-tooltip {
  z-index: $layer-modal;
  background-color: $select-dropdown-bg;
  color: $select-dropdown-fg;
  transition: opacity $transition-time ease-in-out;
  border-radius: $border-radius;
  padding: 0;

  &.react-tooltip__show {
    opacity: 1;
  }

  .select {
    &__menu {
      position: relative !important;
      z-index: $layer-modal !important;
      background-color: $select-dropdown-bg !important;
      margin: 0 !important;

      > *::-webkit-scrollbar {
        width: 8px;
      }

      > *::-webkit-scrollbar-track {
        background: $select-dropdown-bg;
        border-radius: $border-radius;
      }

      > *::-webkit-scrollbar-thumb {
        background-color: $select-dropdown-fg;
        border-radius: calc($border-radius / 2);
        border: 0px solid $select-dropdown-bg;
      }
    }

    &__option {
      min-width: $input-min-width;
      background-color: transparent !important;
      color: $select-dropdown-fg !important;
      transition: color $transition-time ease-in-out !important;
      padding: 0.6em 1em !important;
      margin: 0 !important;
      font-size: 1.1em !important;
      font-weight: normal !important;
      white-space: nowrap !important;
      cursor: pointer;

      &--is-focused {
        color: $select-dropdown-hover-fg !important;
      }

      &--is-disabled {
        opacity: 0.4 !important;
      }

      .select-option-icon {
        display: inline-block;
        width: 1em;
        margin-right: 1em;
      }

      .select-extra {
        margin-left: 1em;
        font-size: 0.9em;
        font-style: italic;
        color: fade-out($fg, 0.3);

        &:before {
          content: '(';
        }

        &:after {
          content: ')';
        }
      }
    }
  }
}
