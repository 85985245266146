// Colours
$brand-bg-1: #222831;
$brand-bg-2: #31363F;
$brand-fg-1: #89AAAD;
$brand-fg-2: #B5C2CE;
$brand-fg-3: #EEEEEE;

// Fonts
$font-stack: "Open Sans", sans-serif;
$font-size: medium;
$title-font-stack: "Roboto Condensed", sans-serif;
$code-font-stack: "Fira Code", monospace;

// Misc
$border-radius: 4px;
$transition-time: .2s;
$page-padding: 2em;
$bar-size: 60px;
$page-min-width: 460px;
$page-max-width: 1080px;
$page-small-width: 800px;
$page-x-small-width: 550px;

// Layers
$layer-background: 10;
$layer-content: 20;
$layer-header: 30;
$layer-footer: 30;
$layer-menu: 40;
$layer-modal: 50;

// Main colours
$fg: $brand-fg-3;
$bg: $brand-bg-1;
$selection-bg: $brand-fg-2;
$selection-fg: lighten($brand-bg-1, 6%);
$scrollbar-bg: $brand-bg-2;
$scrollbar-fg: darken($brand-bg-1, 6%);
$link-fg: $brand-fg-2;
$link-hover-fg: $brand-fg-3;
$tooltip-bg: darken($brand-bg-1, 6%);
$tooltip-fg: $brand-fg-3;

// Intent colours
$intent-success: #5cb85c;
$intent-info: #45a0bc;
$intent-warning: #f0ad4e;
$intent-danger: #d9534f;

// Inputs
$input-min-width: 240px;
$input-bg: $brand-bg-2;
$input-fg: $brand-fg-3;
$input-border: $brand-bg-2;
$input-border-width: 3px;
$input-focus-bg: $brand-bg-2;
$input-focus-fg: $brand-fg-3;
$input-focus-border: $brand-fg-2;
$input-placeholder: fade-out($input-fg, 0.7);

// Json
$json-property: $brand-fg-3;
$json-punctuation: fade-out($brand-fg-3, 0.5);
$json-operator: fade-out($brand-fg-3, 0.5);
$json-index: fade-out($brand-fg-3, 0.7);
$json-number: $intent-success;
$json-string: $intent-info;
$json-boolean: $intent-warning;
$json-null: $intent-danger;

// HTTP
$http-method: $intent-info;
$http-url: $brand-fg-3;

// Code
$code-identifier: $brand-fg-3;
$code-keyword: $intent-info;
$code-string: $intent-warning;
$code-number: $intent-success;
$code-comment: fade-out($brand-fg-3, 0.5);
$code-punctuation: fade-out($brand-fg-3, 0.5);
$code-operator: $brand-fg-3;
