@import '../themes/default';

.token-permission-card {
  .tag.token-permission-card-tag-all-lists,
  .tag.token-permission-card-tag-all-items,
  .tag.token-permission-card-tag-all-indexes {
    color: $brand-fg-3;
    background-color: darken($brand-bg-1, 6%);
  }
}

.card.token-permission-action-card {
  margin: 2em 0;
}
