@import '../themes/default';
@import './Rating';

$search-results-icon-size: 2em;
$search-results-relevance-size: calc($rating-width + 1em);
$search-results-menu-item-hover-bg: lighten($bg, 2%);

.search-results-menu-item {
  width: 100%;
  display: grid;
  padding: 0.5em 0;
  grid-template-areas: "icon details relevance";
  grid-template-columns: $search-results-icon-size 1fr $search-results-relevance-size;
  border-radius: $border-radius;
  transition: background-color $transition-time ease-in-out;

  .search-results-menu-item-icon {
    grid-area: icon;
    width: $search-results-icon-size;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 0.5em;
    }
  }

  .search-results-menu-item-details {
    grid-area: details;

    .search-results-menu-item-id {
      display: inline-block;
      font-size: 0.8em;
      font-style: italic;
      width: 25%;
      margin: 0 1em;
    }

    .search-results-menu-item-name {
      font-weight: bold;
    }
  }

  .search-results-menu-item-relevance {
    grid-area: relevance;
    width: $search-results-relevance-size;

    .rating {
      top: -4px;
    }
  }

  &:hover {
    background-color: $search-results-menu-item-hover-bg;
  }
}
