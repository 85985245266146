@import '../themes/default';

.array-input-container {
  display: inline-block;
}

.array-input.token-input-container {
  min-width: $input-min-width;
  padding: 0;
  margin: 1em 0;
  height: auto;
  border: $input-border-width solid $input-border;
  border-radius: $border-radius;
  background-color: $input-bg;
  transition: all $transition-time ease-in-out;

  &.array-input-focused {
    border-color: $input-focus-border;
    background-color: $input-focus-bg;
  }

  &.array-input-disabled {
    opacity: 0.4;
  }

  input {
    min-width: 0;
    transition-property: none;
    color: $input-fg;
    margin: 0.2em 0.5em;
  }

  .token-input-token {
    margin: 0.2em;
    background-color: darken($brand-bg-1, 6%);
    color: $brand-fg-3;
    font-weight: normal;

    &:hover {
      background-color: darken($brand-bg-1, 6%);
    }

    .token-input-token__delete-button {
      border-width: 0;
      transition: all $transition-time ease-in-out;

      &:hover {
        background-color: transparent;
      }

      .token-input-delete-button__close-icon:after,
      .token-input-delete-button__close-icon:before {
        border-color: $brand-fg-3;
      }
    }
  }
}

.token-input-container .token-input-token-list .token-input-token.token-input-token--active .token-input-autosized-wrapper input {
  border-bottom: 0;
}
